import React, { useRef } from "react";
import "../styles/ThankYouPage.scss";
import html2canvas from "html2canvas";

function ThankYouPage({ formData }) {
  const captureImage = (domElement) => {
    html2canvas(domElement).then((canvas) => {
      saveAs(canvas.toDataURL(), `record-${formData.code}.jpg`);
    });
  };
  const canvasRef = useRef();

  function saveAs(uri, filename) {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;
      //Firefox requires the link to be in the body
      document.body.appendChild(link);
      //simulate click
      link.click();
      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  function convertDateFormat(inputDate) {
    const formattedDate = inputDate.replace("T", " ");
    return formattedDate;
  }
  return (
    <div className="thank-you-page-container">
      <div className="thank-you-title">閣下已成功提交申請</div>
      <div className="thank-you-title">
        你的申請正在審核並將會於三個工作天內收到確認電郵{" "}
      </div>

      <div className="capture-container" ref={canvasRef}>
        <div id="capture" className="capture">
          <div className="event-record">
            Kotex 高潔絲 2024 - 2025{" "}
            {formData.event == 1 ? (
              <span className="event-name">護理包派發</span>
            ) : (
              <span className="event-name">學生講座+護理包派發</span>
            )}{" "}
            申請記錄
          </div>
          <div className="detail-info">以下是報名內容及資料</div>
          <div className="unique-code">您的活動編號為: {formData.code} </div>
          <div className="thank-you-data-container">
            <div className="thank-you-data-text">
              學校名稱: {formData.schoolName}
            </div>
            <div className="thank-you-data-text">
              學校老師: {formData.teacherName}
            </div>
          </div>
          <div className="thank-you-data-container">
            <div className="thank-you-data-text">
              學生年級:{" "}
              {formData.studentYear == 1 ? (
                <span>小五</span>
              ) : (
                <span>中一</span>
              )}
            </div>
            <div className="thank-you-data-text">
              禮品包派發數量: {formData.giftCount}
            </div>
          </div>
          {formData.event == 1 ? (
            <span></span>
          ) : (
            <div className="thank-you-data-container">
              <div className="thank-you-data-text">
                參加講座女生人數:{" "}
                {formData.studentCount == null ? (
                  <span>0</span>
                ) : (
                  formData.studentCount
                )}
              </div>
              <div className="thank-you-data-text">
                第一個日期及時間: {convertDateFormat(formData.eventDateTimeA)}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="download-button-container">
        <div
          className="download-button"
          onClick={() => captureImage(canvasRef.current)}
        >
          下載申請記錄
        </div>
      </div>
      <div className="thank-you-sub-title">
        感謝您們的參與！有關活動詳情，稍後閣下將會收到確認電郵。
      </div>
      <div className="thank-you-text">
        如有任何查詢，請聯絡我們的熱線：<br></br>
        <br></br>
        工作時間：<br></br>
        週一至週五上午十一時至下午六時，公眾假期除外<br></br>
        電子郵箱：info@chillstermarketing.com<br></br>
        電話號碼：+852 5239 9531
      </div>
    </div>
  );
}

export default ThankYouPage;
