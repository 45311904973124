import { useState, useEffect } from "react";
import "./styles/App.scss";
import Form from "./pages/Form";
import ThankYouPage from "./pages/ThankYouPage";

function App() {
  //currentPage: 0 = Form, 1 = ThankYouPage.
  const [currentPage, setCurrentPage] = useState(0);
  const [width, setWidth] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className="App">
      <div className="logo-container">
        {width <= 640 ? (
          <img
            className="logo"
            alt="kotex-logo"
            src="./assets/kotex-logo-m.jpg"
          />
        ) : (
          <img
            className="logo"
            alt="kotex-logo"
            src="./assets/kotex-logo.jpg"
          />
        )}
      </div>
      <div className="form-title">
        Kotex 高潔絲 2024 - 2025 學生講座及護理包派發申請表格
      </div>
      {currentPage === 0 ? (
        <Form setCurrentPage={setCurrentPage} setFormData={setFormData} />
      ) : (
        <ThankYouPage formData={formData} />
      )}
    </div>
  );
}

export default App;
